import {handleResponseError, logError} from "./ErrorHandling";
import {IMAGES_API_IRI, IMAGES_API_URL} from "./Request";
import {getUserData} from "./UserData";

export const MAX_IMAGE_SIZE = 10000000;

//TODO: extract to component and use dataProvider

export const transformImage = async (data) => {
  if (!data) {
    return null;
  }
  if (data.id) {
    return IMAGES_API_IRI + '/' + data.id;
  }
  if (!data.rawFile instanceof File) {
    return null;
  }

  const headers = new Headers({'Content-Type': 'application/json'});
  const userData = getUserData();
  const base64 = await convertFileToBase64(data);
  if (!base64) {
    return null;
  }
  const fileData = {
    name: data.rawFile.name,
    image: base64.split(',')[1]
  };
  headers.set('Authorization', `Bearer ${userData.token}`);

  try {
    const response = await fetch(IMAGES_API_URL, {
      headers: headers,
      method: 'POST',
      body: JSON.stringify(fileData)
    });
    if (!response.ok) {
      await handleResponseError(response);

      return null;
    }

    const responseData = await response.json();

    return IMAGES_API_IRI + '/' + responseData.id;
  } catch (error) {
    logError(error);

    return null;
  }
};

export const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });