import React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  useAuthenticated
} from 'react-admin';
import {DEFAULT_ITEMS_PER_PAGE} from "../constants/Request";
import BookmarksIcon from '@material-ui/icons/Bookmarks';

export const PublicationBookmarkList = props => {
  useAuthenticated();

  return (<List perPage={DEFAULT_ITEMS_PER_PAGE}
                filters={<PublicationBookmarkFilter/>}
                {...props}
  >
    <Datagrid>
      <TextField source="id"/>
      <DateField source="createdAt" label={'Created At'} showTime={true}/>
      <ReferenceField label="Publication" source="publication.id" reference="publications">
        <TextField source="title"/>
      </ReferenceField>
      <ReferenceField label="User" source="user.id" reference="users">
        <TextField source="email"/>
      </ReferenceField>
      <ShowButton/>
    </Datagrid>
  </List>);
};

const PublicationBookmarkFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Publication" source="publication" reference="publications" alwaysOn
                    filterToQuery={(searchText) => {
                      if (searchText) {
                        return {title: searchText};
                      }
                    }}>
      <AutocompleteInput optionText="title"/>
    </ReferenceInput>
    <ReferenceInput label="User" source="user" reference="users" alwaysOn filterToQuery={(searchText) => {
      if (searchText) {
        return {searchQuery: searchText};
      }
    }}>
      <AutocompleteInput optionText="email"/>
    </ReferenceInput>
  </Filter>
);

export const PublicationBookmarkShow = props => {
  useAuthenticated();

  return (
    <Show title="Publication Bookmark" {...props}>
      <SimpleShowLayout>
        <DateField label="Created At" source="createdAt" showTime={true}/>
        <ReferenceField label="Publication" source="publication.id" reference="publications">
          <TextField source="title"/>
        </ReferenceField>
        <ReferenceField label="User" source="user.id" reference="users">
          <TextField source="email"/>
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};

const publicationBookmarksConfiguration = {
  icon: BookmarksIcon,
  list: PublicationBookmarkList,
  options: {label: 'Publication Bookmarks'},
  show: PublicationBookmarkShow,
};

export default publicationBookmarksConfiguration;