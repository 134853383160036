export const BASE_URL = 'https://stage.sharespace.health';
// export const BASE_URL = 'http://localhost';
export const API_PREFIX = 'api';
export const BASE_API_URL = BASE_URL + '/' + API_PREFIX;

export const USERS_URI_PREFIX = 'users';
export const INTEREST_TOPICS_URI_PREFIX = 'interest_topics';
export const INTEREST_GROUPS_URI_PREFIX = 'interest_groups';
export const IMAGES_URI_PREFIX = 'images';
export const GROUPS_URI_PREFIX = 'groups';

export const LOGIN_API_URL = BASE_API_URL + '/authentication_token';
export const USERS_API_IRI = '/' + API_PREFIX + '/' + USERS_URI_PREFIX;
export const INTEREST_TOPICS_API_IRI = '/' + API_PREFIX + '/' + INTEREST_TOPICS_URI_PREFIX;
export const INTEREST_GROUPS_API_IRI = '/' + API_PREFIX + '/' + INTEREST_GROUPS_URI_PREFIX;
export const IMAGES_API_URL = BASE_API_URL + '/' + IMAGES_URI_PREFIX;
export const IMAGES_API_IRI = '/' + API_PREFIX + '/' + IMAGES_URI_PREFIX;
export const GROUPS_API_IRI = '/' + API_PREFIX + '/' + GROUPS_URI_PREFIX;

export const DEFAULT_ITEMS_PER_PAGE = 25;
