import React from 'react';
import {
  AutocompleteArrayInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  email,
  EmailField,
  Filter,
  ImageField,
  ImageInput,
  List,
  minLength,
  PasswordInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useAuthenticated
} from 'react-admin';
import UserIcon from '@material-ui/icons/People';
import {DEFAULT_ITEMS_PER_PAGE} from "../constants/Request";
import InterestTopicGroupsField from "../components/InterestTopicGroupsField";
import {MAX_IMAGE_SIZE, transformImage} from "../constants/Image";
import {groupsIdsToIRI} from "../constants/InterestTopic";
import {isSuperAdmin} from "../constants/UserData";

export const UserList = props => {
  useAuthenticated();

  return (<List perPage={DEFAULT_ITEMS_PER_PAGE}
                filters={<UserFilter/>}
                {...props}
  >
    <Datagrid>
      <TextField source="id" sortable={false}/>
      <DateField source="createdAt" label={'Registration Date'} showTime={true}/>
      <BooleanField source="enabled"/>
      <EmailField source="email"/>
      <TextField source="phone" sortable={false}/>
      <TextField source="nickname"/>
      <TextField source="fullName"/>
      <ImageField source="image.url" label={'Image'} sortable={false}/>
      <InterestTopicGroupsField source="interestTopics" label={'Topic Groups'} sortable={false}/>
      <BooleanField source="additionalInfo.euResident" label={'EU IP?'}/>
      <TextField source="additionalInfo.registrationIP" label={'IP-address'}/>
      <EditButton/>
      <ShowButton/>
    </Datagrid>
  </List>);
};

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput source="searchQuery" defaultValue="" alwaysOn/>
    <TextInput source="id" defaultValue=""/>
    <TextInput source="nickname" defaultValue=""/>
    <TextInput source="email" defaultValue="" type={'email'}/>
    <TextInput source="fullName" defaultValue=""/>
    <TextInput source="description" defaultValue=""/>
  </Filter>
);

export const UserShow = props => {
  useAuthenticated();

  return (
    <Show title="User" {...props}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <DateField source="createdAt" label={'Registration Date'} showTime={true}/>
        <BooleanField source="enabled"/>
        <EmailField source="email"/>
        <TextField source="phone"/>
        <TextField source="nickname"/>
        <TextField source="fullName"/>
        <DateField source="birthDate" label={'Birth Date'}/>
        <ImageField source="image.url" label={'Image'}/>
        <InterestTopicGroupsField source="interestTopics" label={'Topic Groups'}/>
        <BooleanField source="additionalInfo.euResident" label={'IP from EU'}/>
        <TextField source="additionalInfo.registrationIP" label={'Registration IP'}/>
        {/*<SelectField source="gender" choices={[*/}
        {/*  {id: 'M', name: 'Male'},*/}
        {/*  {id: 'F', name: 'Female'},*/}
        {/*]}/>*/}
      </SimpleShowLayout>
    </Show>
  );
};

export const UserCreate = props => {
  useAuthenticated();

  const transform = async data => {
    data.image = await transformImage(data.image);
    data.interestGroups = groupsIdsToIRI(data.interestGroups);

    return data;
  };

  return (
    <Create title="Add User" transform={transform} {...props}>
      <SimpleForm>
        <BooleanInput source="enabled" defaultValue={true}/>
        <TextInput source="nickname" validate={[required()]}/>
        <TextInput source="email" type={'email'} validate={[required(), email()]}/>
        <PasswordInput source="plainPassword" label={'Password'} validate={[required(), minLength(8)]}/>
        {isSuperAdmin() &&
        <SelectArrayInput source="roles" choices={[
          {id: 'ROLE_USER', name: 'ROLE_USER'},
          {id: 'ROLE_ADMIN', name: 'ROLE_ADMIN'},
          {id: 'ROLE_SUPER_ADMIN', name: 'ROLE_SUPER_ADMIN'},
        ]}/>
        }
        <TextInput source="fullName"/>
        <TextInput source="phone" type={'tel'}/>
        <DateInput source="birthDate"/>
        <TextInput source="description" multiline={true} rows={10}/>
        <ImageInput source="image" accept="image/png,image/jpg,image/jpeg" maxSize={MAX_IMAGE_SIZE}>
          <ImageField source="url"/>
        </ImageInput>
        <ReferenceInput
          label="Groups"
          source="interestGroups"
          reference="interest_groups"
          filterToQuery={(searchText) => {
            if (searchText) {
              return {title: searchText};
            }
          }}
        >
          <AutocompleteArrayInput optionText="title"/>
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export const UserEdit = props => {
  useAuthenticated();

  const transform = async data => {
    data.image = await transformImage(data.image);
    data.interestGroups = groupsIdsToIRI(data.interestGroupsIds)

    return data;
  };

  return (
    <Edit title="Edit User" transform={transform} {...props}>
      <SimpleForm>
        <BooleanInput source="enabled"/>
        <TextInput source="nickname" validate={[required()]}/>
        <TextInput source="email" type={'email'} validate={[required(), email()]}/>
        <PasswordInput source="newPassword" label={'New Password'} validate={[minLength(8)]}/>
        {isSuperAdmin() &&
        <SelectArrayInput source="roles" choices={[
          {id: 'ROLE_USER', name: 'ROLE_USER'},
          {id: 'ROLE_ADMIN', name: 'ROLE_ADMIN'},
          {id: 'ROLE_SUPER_ADMIN', name: 'ROLE_SUPER_ADMIN'},
        ]}/>
        }
        <TextInput source="fullName"/>
        <TextInput source="phone" type={'tel'}/>
        <DateInput source="birthDate"/>
        <TextInput source="description" multiline={true} rows={10}/>
        <ImageInput source="image" accept="image/png,image/jpg,image/jpeg" maxSize={MAX_IMAGE_SIZE}>
          <ImageField source="url"/>
        </ImageInput>
        <ReferenceInput
          label="Groups"
          source="interestGroupsIds"
          reference="interest_groups"
          filterToQuery={(searchText) => {
            if (searchText) {
              return {title: searchText};
            }
          }}
        >
          <AutocompleteArrayInput optionText="title"/>
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

const usersConfiguration = {
  icon: UserIcon,
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  options: {label: 'Users'},
  show: UserShow,
};

export default usersConfiguration;