import React from 'react';
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  required,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useAuthenticated
} from 'react-admin';
import {DEFAULT_ITEMS_PER_PAGE} from "../constants/Request";
import {userIdToIRI} from "../constants/User";
import {GroupSharp} from "@material-ui/icons";
import {groupIdToIRI} from "../constants/Group";

export const GroupMemberList = props => {
  useAuthenticated();

  return (<List perPage={DEFAULT_ITEMS_PER_PAGE}
                filters={<GroupMemberFilter/>}
                {...props}
  >
    <Datagrid>
      <TextField source="id" sortable={false}/>
      <DateField source="createdAt" label={'Joined At'} showTime={true}/>
      <ReferenceField label="Group" source="group.id" reference="groups" sortable={false}>
        <TextField source="title"/>
      </ReferenceField>
      <ReferenceField label="User" source="user.id" reference="users" sortable={false}>
        <TextField source="email"/>
      </ReferenceField>
      <NumberField source="visitsCount" label={'Visits Count'}/>
      <EditButton/>
      <ShowButton/>
    </Datagrid>
  </List>);
};

const GroupMemberFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Group" source="group" reference="groups" alwaysOn filterToQuery={(searchText) => {
      if (searchText) {
        return {searchQuery: searchText};
      }
    }}>
      <AutocompleteInput optionText="title"/>
    </ReferenceInput>
    <ReferenceInput label="User" source="user" reference="users" alwaysOn filterToQuery={(searchText) => {
      if (searchText) {
        return {searchQuery: searchText};
      }
    }}>
      <AutocompleteInput optionText="email"/>
    </ReferenceInput>
    <TextInput source="id" defaultValue="" alwaysOn/>
  </Filter>
);

export const GroupMemberShow = props => {
  useAuthenticated();

  return (
    <Show title="Group Member" {...props}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <DateField source="createdAt" label={'Joined At'} showTime={true}/>
        <ReferenceField label="Group" source="group.id" reference="groups">
          <TextField source="title"/>
        </ReferenceField>
        <ReferenceField label="User" source="user.id" reference="users">
          <TextField source="email"/>
        </ReferenceField>
        <NumberField source="visitsCount" label={'Supports Count'}/>
      </SimpleShowLayout>
    </Show>
  );
};

export const GroupMemberCreate = props => {
  useAuthenticated();

  const transform = async data => {
    data.group = groupIdToIRI(data.group.id);
    data.user = userIdToIRI(data.user.id);

    return data;
  };

  return (
    <Create title="Add Group Member" transform={transform} {...props}>
      <SimpleForm>
        <ReferenceInput label="Group" source="group.id" reference="groups" validate={[required()]}
                        filterToQuery={(searchText) => {
                          if (searchText) {
                            return {searchQuery: searchText};
                          }
                        }}>
          <AutocompleteInput optionText="title"/>
        </ReferenceInput>
        <ReferenceInput label="User" source="user.id" reference="users" validate={[required()]}
                        filterToQuery={(searchText) => {
                          if (searchText) {
                            return {searchQuery: searchText};
                          }
                        }}>
          <AutocompleteInput optionText="email"/>
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
export const GroupMemberEdit = props => {
  useAuthenticated();

  const transform = async data => {

    return {
      group: groupIdToIRI(data.group.id),
      user: userIdToIRI(data.user.id),
    };
  };

  return (
    <Edit title="Edit Group Member" transform={transform} {...props}>
      <SimpleForm>
        <ReferenceInput label="Group" source="group.id" reference="groups" validate={[required()]}
                        filterToQuery={(searchText) => {
                          if (searchText) {
                            return {searchQuery: searchText};
                          }
                        }}>
          <AutocompleteInput optionText="title"/>
        </ReferenceInput>
        <ReferenceInput label="User" source="user.id" reference="users" validate={[required()]}
                        filterToQuery={(searchText) => {
                          if (searchText) {
                            return {searchQuery: searchText};
                          }
                        }}>
          <AutocompleteInput optionText="email"/>
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

const groupMembersConfiguration = {
  icon: GroupSharp,
  list: GroupMemberList,
  create: GroupMemberCreate,
  edit: GroupMemberEdit,
  options: {label: 'Group Members'},
  show: GroupMemberShow,
};

export default groupMembersConfiguration;