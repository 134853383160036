import React from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Filter,
  List,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useAuthenticated
} from 'react-admin';
import {DEFAULT_ITEMS_PER_PAGE} from "../constants/Request";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import {usersIdsToIRI} from "../constants/User";

export const NotificationsList = props => {
  useAuthenticated();

  return (<List perPage={DEFAULT_ITEMS_PER_PAGE}
                filters={<NotificationsFilter/>}
                {...props}
  >
    <Datagrid>
      <TextField source="id"/>
      <DateField source="createdAt" label={'Created At'} showTime={true}/>
      <TextField source="type"/>
      <TextField source="relatedObject.id" label={'Related Object Id'}/>
      <TextField source="relatedObject.type" label={'Related Object Type'}/>
      <ReferenceField label="Notify User" source="notifyUser.id" reference="users">
        <TextField source="email"/>
      </ReferenceField>
      <ReferenceField label="From User" source="user.id" reference="users">
        <TextField source="email"/>
      </ReferenceField>
      <DeleteButton/>
      <ShowButton/>
    </Datagrid>
  </List>);
};

const NotificationsFilter = (props) => (
  <Filter {...props}>
    <SelectInput source="type" choices={[
      {id: 'publication_support', name: 'New Publication Comment'},
      {id: 'publication_comment', name: 'New Publication Comment'},
      {id: 'friends_new_publication', name: 'New Friends new publication'},
      {id: 'publication_comment_support', name: 'Publication comment supported'},
      {id: 'publication_comment_reply', name: 'New Reply to publication comment'},
      {id: 'added_to_friends', name: 'User added to friends'},
    ]}/>
    <TextInput source="relatedObjectId" defaultValue=""/>
    <ReferenceInput label="Notify User" source="notifyUser" reference="users" filterToQuery={(searchText) => {
      if (searchText) {
        return {searchQuery: searchText};
      }
    }}>
      <AutocompleteInput optionText="email"/>
    </ReferenceInput>
    <ReferenceInput label="From User" source="user" reference="users" filterToQuery={(searchText) => {
      if (searchText) {
        return {searchQuery: searchText};
      }
    }}>
      <AutocompleteInput optionText="email"/>
    </ReferenceInput>
  </Filter>
);

export const NotificationShow = props => {
  useAuthenticated();

  return (
    <Show title="Notification" {...props}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <DateField source="createdAt" label={'Created At'} showTime={true}/>
        <TextField source="type"/>
        <BooleanField source="read"/>
        <BooleanField source="opened"/>
        <TextField source="relatedObject.id" label={'Related Object Id'}/>
        <TextField source="relatedObject.type" label={'Related Object Type'}/>
        <ReferenceField label="Notify User" source="notifyUser.id" reference="users">
          <TextField source="email"/>
        </ReferenceField>
        <ReferenceField label="From User" source="user.id" reference="users">
          <TextField source="email"/>
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};

export const NotificationCreate = props => {
  useAuthenticated();

  const transform = async data => {

    return {
      notifyAll: data.notifyAll,
      notifyUsers: usersIdsToIRI(data.notifyUsers),
      message: data.message
    };
  };

  const notifyUsersValidation = (value, allValues) => {
    if (!value && !allValues.notifyAll) {
      return 'Please, choose users to notify or select "Notify all"';
    }

    return undefined;
  };

  return (
    <Create title="Create System Notification" transform={transform} {...props}>
      <SimpleForm redirect="list">
        <BooleanInput label="Notify all users?" source="notifyAll"/>
        <ReferenceArrayInput label="Notify Users"
                             source="notifyUsers"
                             reference="users"
                             validate={[notifyUsersValidation]}
                             filterToQuery={(searchText) => {
                               if (searchText) {
                                 return {searchQuery: searchText};
                               }
                             }}>
          <AutocompleteArrayInput optionText="email"/>
        </ReferenceArrayInput>
        <TextInput source="message" validate={[required()]} multiline={true} rows={10}/>
      </SimpleForm>
    </Create>
  );
};

const notificationsConfiguration = {
  icon: NotificationsActiveIcon,
  list: NotificationsList,
  options: {label: 'Notifications'},
  show: NotificationShow,
  create: NotificationCreate,
};

export default notificationsConfiguration;