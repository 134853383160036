import React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DeleteButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  useAuthenticated
} from 'react-admin';
import {DEFAULT_ITEMS_PER_PAGE} from "../constants/Request";
import {ChatBubble} from "@material-ui/icons";

export const GroupPublicationCommentList = props => {
  useAuthenticated();

  return (<List perPage={DEFAULT_ITEMS_PER_PAGE}
                filters={<GroupPublicationCommentFilter/>}
                {...props}
  >
    <Datagrid>
      <TextField source="id"/>
      <DateField source="createdAt" label={'Created At'} showTime={true}/>
      <ReferenceField label="Group Publication" source="publication.id" reference="group_publications">
        <TextField source="text"/>
      </ReferenceField>
      <ReferenceField label="User" source="user.id" reference="users">
        <TextField source="email"/>
      </ReferenceField>
      <ReferenceField label="Reply To" source="replyTo.id" reference="group_publication_comments">
        <TextField source="message"/>
      </ReferenceField>
      <TextField source="message" label={'Comment'}/>
      <DeleteButton/>
      <ShowButton/>
    </Datagrid>
  </List>);
};

const GroupPublicationCommentFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Publication" source="publication" reference="group_publications" alwaysOn
                    filterToQuery={(searchText) => {
                      if (searchText) {
                        return {text: searchText};
                      }
                    }}>
      <AutocompleteInput optionText="text"/>
    </ReferenceInput>
    <ReferenceInput label="User" source="user" reference="users" alwaysOn filterToQuery={(searchText) => {
      if (searchText) {
        return {searchQuery: searchText};
      }
    }}>
      <AutocompleteInput optionText="email"/>
    </ReferenceInput>
    <ReferenceInput label="Reply To" source="replyTo" reference="users" filterToQuery={(searchText) => {
      if (searchText) {
        return {searchQuery: searchText};
      }
    }}>
      <AutocompleteInput optionText="email"/>
    </ReferenceInput>
  </Filter>
);

export const GroupPublicationCommentShow = props => {
  useAuthenticated();

  return (
    <Show title="Comment" {...props}>
      <SimpleShowLayout>
        <DateField label="Created At" source="createdAt" showTime={true}/>
        <ReferenceField label="Publication" source="publication.id" reference="group_publications">
          <TextField source="text"/>
        </ReferenceField>
        <ReferenceField label="User" source="user.id" reference="users">
          <TextField source="email"/>
        </ReferenceField>
        <ReferenceField label="Reply To" source="replyTo.id" reference="group_publication_comments">
          <TextField source="message"/>
        </ReferenceField>
        <TextField label="Comment" source="message"/>
      </SimpleShowLayout>
    </Show>
  );
};

const groupPublicationCommentsConfiguration = {
  icon: ChatBubble,
  list: GroupPublicationCommentList,
  options: {label: 'Group Pub Comments'},
  show: GroupPublicationCommentShow,
};

export default groupPublicationCommentsConfiguration;