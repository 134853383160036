import React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DeleteButton,
  Filter,
  linkToRecord,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  TextInput,
  useAuthenticated,
  useRecordContext
} from 'react-admin';
import {DEFAULT_ITEMS_PER_PAGE} from "../constants/Request";
import {Link} from 'react-router-dom';
import ReportIcon from '@material-ui/icons/Report';

const ReportRelatedObjectField = (props) => {
  const record = useRecordContext(props);
  const relatedObjectId = record.relatedObjectId;
  if (record.type === 'user') {
    const linkToUser = linkToRecord('/users', relatedObjectId, 'show');

    return <Link to={linkToUser}>{relatedObjectId}</Link>;
  }
  const linkToPublication = linkToRecord('/publications', relatedObjectId, 'show');

  return <Link to={linkToPublication}>{relatedObjectId}</Link>;
}

ReportRelatedObjectField.defaultProps = {label: 'Related Object Id'};

export const UserReportList = props => {
  useAuthenticated();

  return (<List perPage={DEFAULT_ITEMS_PER_PAGE}
                filters={<UserReportFilter/>}
                {...props}
  >
    <Datagrid>
      <TextField source="id"/>
      <DateField source="createdAt" label={'Created At'} showTime={true}/>
      <ReferenceField label="Reported By" source="user.id" reference="users">
        <TextField source="email"/>
      </ReferenceField>
      <TextField source="type"/>
      <TextField source="relatedObjectId"/>
      <ReportRelatedObjectField source="relatedObjectId"/>
      <TextField source="message"/>
      <DeleteButton/>
      <ShowButton/>
    </Datagrid>
  </List>);
};

const UserReportFilter = (props) => (
  <Filter {...props}>
    <SelectInput source="type" choices={[
      {id: 'user', name: 'User'},
      {id: 'publication', name: 'Publication'},
      {id: 'publication_comment', name: 'Publication Comment'},
    ]}/>
    <TextInput source="relatedObjectId" defaultValue=""/>
    <TextInput source="message" defaultValue=""/>
    <ReferenceInput label="User" source="user" reference="users" filterToQuery={(searchText) => {
      if (searchText) {
        return {searchQuery: searchText};
      }
    }}>
      <AutocompleteInput optionText="email"/>
    </ReferenceInput>
  </Filter>
);

export const UserReportShow = props => {
  useAuthenticated();

  return (
    <Show title="UserReport" {...props}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <DateField source="createdAt" label={'Created At'} showTime={true}/>
        <ReferenceField label="Reported By" source="user.id" reference="users">
          <TextField source="email"/>
        </ReferenceField>
        <TextField source="type"/>
        <ReportRelatedObjectField source="relatedObjectId"/>
        <TextField source="relatedObjectId"/>
        <TextField source="message"/>
      </SimpleShowLayout>
    </Show>
  );
};

const userReportsConfiguration = {
  icon: ReportIcon,
  list: UserReportList,
  options: {label: 'Reports'},
  show: UserReportShow,
};

export default userReportsConfiguration;