import * as React from "react";
import {Admin, Resource} from 'react-admin';
import authProvider from './authProvider';
import {BASE_API_URL} from "./constants/Request";
import myDataProvider from "./dataProvider";
import usersConfiguration from './users';
import interestTopicsConfiguration from './interestTopics';
import interestGroupsConfiguration from './interestGroups';
import faqEntriesConfiguration from './faqEntries';
import publicationsConfiguration from './publications';
import publicationBookmarksConfiguration from './publicationBookmarks';
import publicationLikesConfiguration from './publicationLikes';
import commentsConfiguration from './comments';
import commentLikesConfiguration from './commentLikes';
import supportRequestsConfiguration from "./supportRequests";
import userReportsConfiguration from "./userReports";
import notificationsConfiguration from "./notifications";
import groupsConfiguration from "./groups";
import groupImagesConfiguration from "./groupImages";
import groupPublicationsConfiguration from "./groupPublications";
import groupMembersConfiguration from "./groupMembers";
import groupPublicationCommentsConfiguration from "./groupPublicationComments";

const dataProvider = myDataProvider(BASE_API_URL);

const App = () => (
  <Admin authProvider={authProvider}
         dataProvider={dataProvider}
         title={'ShareSpace Admin'}
         disableTelemetry
  >
    <Resource name="users" {...usersConfiguration} />
    <Resource name="interest_topics" {...interestTopicsConfiguration} />
    <Resource name="interest_groups" {...interestGroupsConfiguration} />
    <Resource name="faq_entries" {...faqEntriesConfiguration} />
    <Resource name="publications" {...publicationsConfiguration} />
    <Resource name="publication_bookmarks" {...publicationBookmarksConfiguration} />
    <Resource name="publication_likes" {...publicationLikesConfiguration} />
    <Resource name="publication_comments" {...commentsConfiguration} />
    <Resource name="publication_comment_likes" {...commentLikesConfiguration} />
    <Resource name="notifications" {...notificationsConfiguration} />
    <Resource name="support_requests" {...supportRequestsConfiguration} />
    <Resource name="user_reports" {...userReportsConfiguration} />
    <Resource name="groups" {...groupsConfiguration} />
    <Resource name="group_publications" {...groupPublicationsConfiguration} />
    <Resource name="group_publication_comments" {...groupPublicationCommentsConfiguration} />
    <Resource name="group_members" {...groupMembersConfiguration} />
    <Resource name="group_images" {...groupImagesConfiguration} />
  </Admin>
);

export default App;