export const handleResponseError = async (response) => {
  try {
    const responseData = await response.json();
    if (responseData.message) {
      throw new Error(responseData.message);
    } else if (responseData['violations']) {
      const errorMessages = responseData['violations'].map(item => item.message)
      throw new Error(errorMessages.join(';'));
    } else if (responseData['hydra:description']) {
      throw new Error(responseData['hydra:description']);
    } else if (responseData['hydra:title']) {
      throw new Error(responseData['hydra:title'] + '. ' + responseData['hydra:description']);
    }
    throw new Error('UNKNOWN_ERROR');
  } catch (error) {
    logError(error);

    return error;
  }
};

export const logError = (error) => {
  alert(error.message);
  console.log(error);
  // Sentry.Native.captureException(error);
};