import React from 'react';
import {
  Datagrid,
  DateField,
  DeleteButton,
  EmailField,
  Filter,
  List,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  TextInput,
  useAuthenticated,
} from 'react-admin';
import {DEFAULT_ITEMS_PER_PAGE} from "../constants/Request";
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

export const SupportRequestsList = props => {
  useAuthenticated();

  return (<List perPage={DEFAULT_ITEMS_PER_PAGE}
                filters={<SupportRequestsFilter/>}
                {...props}
  >
    <Datagrid>
      <TextField source="id"/>
      <DateField source="createdAt" label={'Created At'} showTime={true}/>
      <TextField source="name"/>
      <EmailField source="email"/>
      <TextField source="subject"/>
      <TextField source="message"/>
      <DeleteButton/>
      <ShowButton/>
    </Datagrid>
  </List>);
};

const SupportRequestsFilter = (props) => (
  <Filter {...props}>
    <TextInput source="email" defaultValue="" type={'email'}/>
    <TextInput source="name" defaultValue=""/>
    <TextInput source="subject" defaultValue=""/>
    <TextInput source="message" defaultValue=""/>
  </Filter>
);

export const SupportRequestsShow = props => {
  useAuthenticated();

  return (
    <Show title="Support Request" {...props}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <DateField source="createdAt" label={'Created At'} showTime={true}/>
        <TextField source="name"/>
        <EmailField source="email"/>
        <TextField source="subject"/>
        <TextField source="message"/>
      </SimpleShowLayout>
    </Show>
  );
};

const supportRequestsConfiguration = {
  icon: ContactSupportIcon,
  options: {label: 'Support Requests'},
  list: SupportRequestsList,
  show: SupportRequestsShow,
};

export default supportRequestsConfiguration;