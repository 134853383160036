import React from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanField,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  ImageField,
  ImageInput,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceField,
  ReferenceInput,
  required,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useAuthenticated
} from 'react-admin';
import BookIcon from '@material-ui/icons/Book';
import {DEFAULT_ITEMS_PER_PAGE} from "../constants/Request";
import InterestTopicGroupsField from "../components/InterestTopicGroupsField";
import {MAX_IMAGE_SIZE, transformImage} from "../constants/Image";
import {groupsIdsToIRI} from "../constants/InterestTopic";
import {userIdToIRI} from "../constants/User";

export const PublicationList = props => {
  useAuthenticated();

  return (<List perPage={DEFAULT_ITEMS_PER_PAGE}
                filters={<PublicationFilter/>}
                {...props}
  >
    <Datagrid>
      <TextField source="id" sortable={false}/>
      <DateField source="createdAt" label={'Created At'} showTime={true}/>
      <DateField source="updatedAt" label={'Updated At'} showTime={true}/>
      <BooleanField source="hidden" sortable={false}/>
      <TextField source="title"/>
      <ReferenceField label="Author" source="author.id" reference="users" sortable={false}>
        <TextField source="email"/>
      </ReferenceField>
      <ImageField source="image.url" label={'Image'} sortable={false}/>
      <NumberField source="supportsCount" label={'Supports Count'}/>
      <NumberField source="commentsCount" label={'Comments Count'}/>
      <InterestTopicGroupsField source="interestTopics" sortable={false}/>
      <EditButton/>
      <ShowButton/>
    </Datagrid>
  </List>);
};

const PublicationFilter = (props) => (
  <Filter {...props}>
    <TextInput source="searchQuery" defaultValue="" alwaysOn/>
    <NullableBooleanInput source="hidden"/>
    <ReferenceInput label="User" source="user" reference="users" alwaysOn filterToQuery={(searchText) => {
      if (searchText) {
        return {searchQuery: searchText};
      }
    }}>
      <AutocompleteInput optionText="email"/>
    </ReferenceInput>
    <TextInput source="id" defaultValue="" alwaysOn/>
  </Filter>
);

export const PublicationShow = props => {
  useAuthenticated();

  return (
    <Show title="Publication" {...props}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <DateField source="createdAt" label={'Created At'} showTime={true}/>
        <DateField source="updatedAt" label={'Updated At'} showTime={true}/>
        <BooleanField source="hidden"/>
        <ReferenceField label="Author" source="author.id" reference="users">
          <TextField source="email"/>
        </ReferenceField>
        <TextField source="title"/>
        <InterestTopicGroupsField source="interestTopics" label={'Topic Groups'}/>
        <ImageField source="image.url" label={'Image'}/>
        <NumberField source="supportsCount" label={'Supports Count'}/>
        <NumberField source="commentsCount" label={'Comments Count'}/>
        <TextField source="description"/>
      </SimpleShowLayout>
    </Show>
  );
};

export const PublicationCreate = props => {
  useAuthenticated();

  const transform = async data => {
    data.image = await transformImage(data.image);
    data.interestGroups = groupsIdsToIRI(data.interestGroups);
    data.author = userIdToIRI(data.author);

    return data;
  };

  return (
    <Create title="Add Publication" transform={transform} {...props}>
      <SimpleForm>
        <ReferenceInput label="Author" source="author" reference="users" validate={[required()]}
                        filterToQuery={(searchText) => {
                          if (searchText) {
                            return {searchQuery: searchText};
                          }
                        }}>
          <AutocompleteInput optionText="email"/>
        </ReferenceInput>
        <ImageInput source="image" accept="image/png,image/jpg,image/jpeg" maxSize={MAX_IMAGE_SIZE}>
          <ImageField source="url"/>
        </ImageInput>
        <TextInput source="title" validate={[required()]}/>
        <TextInput source="description" multiline={true} rows={20} validate={[required()]}/>
        <ReferenceInput label="Groups" source="interestGroups" reference="interest_groups"
                        filterToQuery={(searchText) => {
                          if (searchText) {
                            return {title: searchText};
                          }
                        }}>
          <AutocompleteArrayInput optionText="title"/>
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
export const PublicationEdit = props => {
  useAuthenticated();

  const transform = async data => {

    return {
      title: data.title,
      description: data.description,
      author: userIdToIRI(data.author.id),
      image: await transformImage(data.image),
      interestGroups: groupsIdsToIRI(data.interestGroupsIds)
    };
  };

  return (
    <Edit title="Edit Publication" transform={transform} {...props}>
      <SimpleForm>
        <ReferenceInput label="Author" source="author.id" reference="users" validate={[required()]}
                        filterToQuery={(searchText) => {
                          if (searchText) {
                            return {searchQuery: searchText};
                          }
                        }}>
          <AutocompleteInput optionText="email"/>
        </ReferenceInput>
        <ImageInput source="image" accept="image/png,image/jpg,image/jpeg" maxSize={MAX_IMAGE_SIZE}>
          <ImageField source="url"/>
        </ImageInput>
        <TextInput source="title" validate={[required()]}/>
        <TextInput source="description" multiline={true} rows={20} validate={[required()]}/>
        <ReferenceInput label="Groups" source="interestGroupsIds" reference="interest_groups"
                        filterToQuery={(searchText) => {
                          if (searchText) {
                            return {title: searchText};
                          }
                        }}>
          <AutocompleteArrayInput optionText="title"/>
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

const publicationsConfiguration = {
  icon: BookIcon,
  list: PublicationList,
  create: PublicationCreate,
  edit: PublicationEdit,
  options: {label: 'Publications'},
  show: PublicationShow,
};

export default publicationsConfiguration;