import React from 'react';
import {
  ArrayField,
  BooleanField,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  Edit,
  EditButton,
  ImageField,
  ImageInput,
  List,
  number,
  NumberField,
  NumberInput,
  required,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  useAuthenticated
} from 'react-admin';
import {DEFAULT_ITEMS_PER_PAGE} from "../constants/Request";
import ListIcon from "@material-ui/icons/List";
import {MAX_IMAGE_SIZE, transformImage} from "../constants/Image";

export const InterestTopicList = props => {
  useAuthenticated();

  return (<List perPage={DEFAULT_ITEMS_PER_PAGE}
                {...props}
  >
    <Datagrid>
      <TextField source="id"/>
      <BooleanField source="visible"/>
      <NumberField source="position"/>
      <TextField source="title"/>
      <TextField source="color"/>
      <ImageField source="image.url" label={'Image'}/>
      <ArrayField source="groups" label={'Topics'}>
        <SingleFieldList>
          <ChipField source="title"/>
        </SingleFieldList>
      </ArrayField>
      <EditButton/>
      <ShowButton/>
    </Datagrid>
  </List>);
};

export const InterestTopicShow = props => {
  useAuthenticated();

  return (
    <Show title="Group" {...props}>
      <SimpleShowLayout>
        <BooleanField source="visible"/>
        <NumberField source="position"/>
        <TextField source="title"/>
        <TextField source="color"/>
        <ArrayField source="groups" label={'Topics'}>
          <SingleFieldList>
            <ChipField source="title"/>
          </SingleFieldList>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export const InterestTopicCreate = props => {
  useAuthenticated();

  const transform = async data => {
    data.image = await transformImage(data.image);

    return data;
  };

  return (
    <Create title="Add Group" transform={transform} {...props}>
      <SimpleForm>
        <BooleanInput source="visible"/>
        <NumberInput source="position" step={1} validate={[number()]}/>
        <TextInput source="title" validate={[required()]}/>
        <TextInput source="color" validate={[required()]}/>
        <ImageInput source="image" accept="image/png,image/jpg,image/jpeg" maxSize={MAX_IMAGE_SIZE}>
          <ImageField source="url"/>
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export const InterestTopicEdit = props => {
  useAuthenticated();

  const transform = async data => {
    data.image = await transformImage(data.image);

    return data;
  };

  return (
    <Edit title="Edit Group" transform={transform} {...props}>
      <SimpleForm>
        <BooleanInput source="visible"/>
        <NumberInput source="position" step={1} validate={[number()]}/>
        <TextInput source="title" validate={[required()]}/>
        <TextInput source="color" validate={[required()]}/>
        <ImageInput source="image" accept="image/png,image/jpg,image/jpeg" maxSize={MAX_IMAGE_SIZE}>
          <ImageField source="url"/>
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

const interestTopicsConfiguration = {
  icon: ListIcon,
  list: InterestTopicList,
  create: InterestTopicCreate,
  edit: InterestTopicEdit,
  options: {label: 'Interest Topics'},
  show: InterestTopicShow,
};

export default interestTopicsConfiguration;
