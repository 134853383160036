import {getInterestGroupsFromTopic} from "../constants/InterestTopic";
import Chip from '@material-ui/core/Chip';
import {makeStyles} from '@material-ui/core/styles';

const InterestTopicGroupsField = ({record}) => {
  const classes = useStyles();

  return record.interestTopics.map((topic) => {
    const groups = getInterestGroupsFromTopic(topic);
    return groups.map(item => (
      <Chip key={item.id} label={item.title} className={classes.chip}/>
    ));
  })

}

InterestTopicGroupsField.defaultProps = {
  addLabel: true
};

const useStyles = makeStyles({
  chip: {
    margin: '4px',
  },
});

export default InterestTopicGroupsField;