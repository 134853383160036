import {LOGIN_API_URL} from "./constants/Request";
import {clearUserData, getUserData, getUserPermissions, setUserData, setUserPermissions} from "./constants/UserData";

export const rolesContainAdminRole = function (roles) {
  return roles && roles.some(role => role === 'ROLE_ADMIN' || role === 'ROLE_SUPER_ADMIN');
};

const authProvider = {
  login: ({username, password}) => {
    const request = new Request(LOGIN_API_URL, {
      method: 'POST',
      body: JSON.stringify({email: username, password: password}),
      headers: new Headers({'Content-Type': 'application/json'}),
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        if (!rolesContainAdminRole(data.roles)) {
          throw new Error('User is not admin');
        }

        setUserData(data);
        setUserPermissions(data.roles);

        return true;
      });
  },

  logout: () => {
    clearUserData();

    return Promise.resolve();
  },

  checkAuth: () => {
    //TODO: check if credentials are not expired
    return getUserData() ? Promise.resolve() : Promise.reject();
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      clearUserData();

      // return Promise.reject({ redirectTo: '/login' });
      return Promise.reject();
    }

    return Promise.resolve();
  },

  getPermissions: () => {
    const data = getUserPermissions();

    if (data && data.roles && rolesContainAdminRole(data.roles)) {
      return Promise.resolve();
    }

    return Promise.reject();
  }
};

export default authProvider;