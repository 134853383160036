export const getUserData = () => {
  return JSON.parse(localStorage.getItem('userData'))
};

export const setUserData = (data) => {
  const expirationDate = new Date(
    new Date().getTime() + parseInt(data.tokenTTL) * 1000
  );

  localStorage.setItem(
    'userData',
    JSON.stringify({
      token: data.token,
      userId: data.userId,
      expiryDate: expirationDate.toISOString()
    })
  );
};

export const clearUserData = () => {
  localStorage.removeItem('userData');
}

export const getUserPermissions = () => {
  return JSON.parse(localStorage.getItem('permissions'))
};

export const setUserPermissions = (roles) => {
  localStorage.setItem(
    'permissions',
    JSON.stringify({
      roles: roles
    })
  );
};

export const isSuperAdmin = function () {
  const userPermissions = getUserPermissions();

  return userPermissions && userPermissions.roles && userPermissions.roles.some(role => role === 'ROLE_SUPER_ADMIN');
};
