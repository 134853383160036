import {GROUPS_API_IRI} from "./Request";

export const groupIdToIRI = (id) => {
  if (!id) {
    return null;
  }

  return GROUPS_API_IRI + '/' + id
}

export const groupsIdsToIRI = (users) => {
  const res = [];

  if (!users || !users.length) {
    return res;
  }

  for (const key in users) {
    res.push(GROUPS_API_IRI + '/' + users[key]);
  }

  return res;
}