import {USERS_API_IRI} from "./Request";

export const userIdToIRI = (id) => {
  if (!id) {
    return null;
  }

  return USERS_API_IRI + '/' + id
}

export const usersIdsToIRI = (users) => {
  const res = [];

  if (!users || !users.length) {
    return res;
  }

  for (const key in users) {
    res.push(USERS_API_IRI + '/' + users[key]);
  }

  return res;
}