import {stringify} from 'query-string';
import {HttpError} from 'ra-core';
import {handleResponseError} from "./constants/ErrorHandling";
import {getUserData} from "./constants/UserData";

const dataProvider = (apiUrl) => {

  const httpClient = (url, method, body) => {
    const headers = new Headers({'Content-Type': 'application/json'});
    const data = getUserData();
    const {token, userId, expiryDate} = data;

    //TODO: throw error if token is not found
    headers.set('Authorization', `Bearer ${token}`);

    return fetchJson(url, headers, method, body);
  };

  const fetchJson = (url, headers, method, body) => {
    return fetch(url, {
      headers: headers,
      method: method ? method : 'GET',
      body: body ? body : null
    })
      .then((response) => {
        if (!response.ok) {
          return handleResponseError(response).then((error) => {
            return Promise.reject(
              new HttpError(
                error.message,
                response.status,
              )
            );
          });
        }
        if (method === 'DELETE') {
          return Promise.resolve({success: true});
        }

        return response.json();
      })
      .then((data) => {
        return Promise.resolve(data);
      });
  };

  const formatFilters = filters => {
    let res = {};
    for (let key in filters) {
      if (filters[key] === '') {
        continue;
      }

      switch (key) {
        case 'publication':
        case 'user':
        case 'group':
          res[key + '.id'] = filters[key];
          break;
        default:
          res[key] = filters[key];
      }
    }

    return res;
  }

  const formatSort = sort => {
    const {field, order} = sort;
    let res = {};
    res['order['+field+']'] = order;

    return res;
  }

  return {
    getList: (resource, params) => {
      const {page: currentPage, perPage} = params.pagination;
      const filters = params.filter;
      const sort = params.sort;

      const query = {
        page: currentPage,
        itemsPerPage: perPage,
        ...formatFilters(filters),
        ...formatSort(sort)
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url).then((data) => {
        return {
          data: data['hydra:member'],
          total: data['hydra:totalItems'],
        };
      });
    },

    getOne: (resource, params) => {
      return httpClient(`${apiUrl}/${resource}/${params.id}`).then((data) => ({
        data: data,
      }))
    },

    getMany: (resource, params) => {
      const filters = params.filter;
      let ids = params.ids;
      if (ids && ids.length > 0) {
        if (Array.isArray(ids[0])) {
          ids = ids[0];
        }
      }

      const query = {
        sortBy: 'id',
        currentPage: 1,
        perPage: 100,
        'ids[]': ids,
        ...filters,
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url).then((data) => {

        return {
          data: data['hydra:member'],
          total: data['hydra:totalItems'],
        };
      });
    },

    getManyReference: (resource, params) => {
      const filters = params.filter;
      const query = {
        sortBy: 'id',
        currentPage: 1,
        perPage: 100,
        ...filters,
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url).then((data) => {
        return {
          data: data,
          total: data['hydra:totalItems'],
        };
      });
    },

    update: (resource, params) => {
      const url = `${apiUrl}/${resource}/${params.id}`;
      const body = JSON.stringify(params.data);

      return httpClient(url, 'PUT', body).then((data) => {
        return {
          data: data,
        };
      });
    },
    updateMany: () => Promise.reject(),
    create: (resource, params) => {
      let url = `${apiUrl}/${resource}`;
      if (resource === 'notifications') {
        url = `${apiUrl}/${resource}/system/create`;
      }
      const body = JSON.stringify(params.data);

      return httpClient(url, 'POST', body).then((data) => {
        if (resource === 'notifications') {
          data.id = data['@id'];
        }

        return {
          data: data,
        };
      });
    },
    delete: (resource, params) => {
      const url = `${apiUrl}/${resource}/${params.id}`;
      return httpClient(url, 'DELETE').then((data) => ({
        data: params.previousData,
      }))
    },
    deleteMany: (resource, params) => {
      //TODO: optimize
      for (const key in params.ids) {
        httpClient(`${apiUrl}/${resource}/${params.ids[key]}`, 'DELETE')
          .then(data => console.log(data))
          .catch(function (e) {
            alert(e.message);
          });
      }

      return Promise.resolve();
    },
  };
};

export default dataProvider;