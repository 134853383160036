import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  number,
  NumberField,
  NumberInput,
  required,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useAuthenticated,
} from 'react-admin';
import {DEFAULT_ITEMS_PER_PAGE} from "../constants/Request";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

export const FAQEntryList = props => {
  useAuthenticated();

  return (<List perPage={DEFAULT_ITEMS_PER_PAGE}
                {...props}
  >
    <Datagrid>
      <TextField source="id"/>
      <BooleanField source="visible"/>
      <NumberField source="position"/>
      <TextField source="title"/>
      <TextField source="content"/>
      <EditButton/>
      <ShowButton/>
    </Datagrid>
  </List>);
};

export const FAQEntryShow = props => {
  useAuthenticated();

  return (
    <Show title="FAQ Entries" {...props}>
      <SimpleShowLayout>
        <BooleanField source="visible"/>
        <NumberField source="position"/>
        <TextField source="title"/>
        <TextField source="content"/>
      </SimpleShowLayout>
    </Show>
  );
};

export const FAQEntryCreate = props => {
  useAuthenticated();

  return (
    <Create title="Add FAQ entry" {...props}>
      <SimpleForm>
        <BooleanInput source="visible"/>
        <NumberInput source="position" step={1} validate={[number()]}/>
        <TextInput source="title" validate={[required()]}/>
        <TextInput source="content" type={'textarea'} multiline={true} rows={20} validate={[required()]}/>
      </SimpleForm>
    </Create>
  );
};

export const FAQEntryEdit = props => {
  useAuthenticated();

  return (
    <Edit title="Edit FAQ entry" {...props}>
      <SimpleForm>
        <BooleanInput source="visible"/>
        <NumberInput source="position" step={1} validate={[number()]}/>
        <TextInput source="title" validate={[required()]}/>
        <TextInput source="content" type={'textarea'} multiline={true} rows={20} validate={[required()]}/>
      </SimpleForm>
    </Edit>
  );
};

const faqEntriesConfiguration = {
  icon: QuestionAnswerIcon,
  list: FAQEntryList,
  create: FAQEntryCreate,
  edit: FAQEntryEdit,
  options: {label: 'FAQ'},
  show: FAQEntryShow,
};

export default faqEntriesConfiguration;