import {INTEREST_GROUPS_API_IRI, INTEREST_TOPICS_API_IRI} from "./Request";

export const getInterestGroupsFromTopic = (topic) => {
  const groups = [];

  if (!topic.groups || (!topic.groups.length && typeof topic.groups !== 'object')) {
    return groups;
  }

  for (const key in topic.groups) {
    let group = topic.groups[key];
    groups.push(group);
  }

  return groups;
};

export const topicIdToIRI = (id) => {
  if (!id) {
    return null;
  }

  return INTEREST_TOPICS_API_IRI + '/' + id
}

export const groupsIdsToIRI = (groups) => {
  const res = [];

  if (!groups || !groups.length) {
    return res;
  }

  for (const key in groups) {
    res.push(INTEREST_GROUPS_API_IRI + '/' + groups[key]);
  }

  return res;
}