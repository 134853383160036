import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  number,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useAuthenticated
} from 'react-admin';
import ListIcon from '@material-ui/icons/List';
import {DEFAULT_ITEMS_PER_PAGE} from "../constants/Request";
import {topicIdToIRI} from "../constants/InterestTopic";

export const InterestGroupList = props => {
  useAuthenticated();

  return (<List perPage={DEFAULT_ITEMS_PER_PAGE}
                {...props}
  >
    <Datagrid>
      <TextField source="id"/>
      <BooleanField source="visible"/>
      <NumberField source="position"/>
      <TextField source="title"/>
      <TextField source="color"/>
      <BooleanField source="explicit"/>
      <ReferenceField label="Group" source="topic.id" reference="interest_topics">
        <TextField source="title"/>
      </ReferenceField>
      <EditButton/>
      <ShowButton/>
    </Datagrid>
  </List>);
};

export const InterestGroupShow = props => {
  useAuthenticated();

  return (
    <Show title="Topic" {...props}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <BooleanField source="visible"/>
        <NumberField source="position"/>
        <TextField source="title"/>
        <TextField source="color"/>
        <BooleanField source="explicit"/>
        <ReferenceField label="Group" source="topic.id" reference="interest_topics">
          <TextField source="title"/>
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};

export const InterestGroupCreate = props => {
  useAuthenticated();

  const transform = async data => {
    data.topic = topicIdToIRI(data.topic);

    return data;
  };

  return (
    <Create title="Add Topic" transform={transform} {...props}>
      <SimpleForm>
        <BooleanInput source="visible"/>
        <NumberInput source="position" step={1} validate={[number()]}/>
        <TextInput source="title" validate={[required()]}/>
        <TextInput source="color" validate={[required()]}/>
        <BooleanInput source="explicit"/>
        <ReferenceInput label="Group" source="topic" reference="interest_topics" validate={[required()]}>
          <SelectInput optionText="title"/>
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export const InterestGroupEdit = props => {
  useAuthenticated();

  const transform = async data => {
    data.topic = topicIdToIRI(data.topic.id);

    return data;
  };

  return (
    <Edit title="Edit Topic" transform={transform} {...props}>
      <SimpleForm>
        <BooleanInput source="visible"/>
        <NumberInput source="position" step={1} validate={[number()]}/>
        <TextInput source="title" validate={[required()]}/>
        <TextInput source="color" validate={[required()]}/>
        <BooleanInput source="explicit"/>
        <ReferenceInput label="Group" source="topic.id" reference="interest_topics" validate={[required()]}>
          <SelectInput optionText="title"/>
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

const interestGroupsConfiguration = {
  icon: ListIcon,
  list: InterestGroupList,
  create: InterestGroupCreate,
  edit: InterestGroupEdit,
  options: {label: 'Interest Groups'},
  show: InterestGroupShow,
};

export default interestGroupsConfiguration;
