import React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  useAuthenticated
} from 'react-admin';
import {DEFAULT_ITEMS_PER_PAGE} from "../constants/Request";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

export const CommentLikeList = props => {
  useAuthenticated();

  return (<List perPage={DEFAULT_ITEMS_PER_PAGE}
                filters={<CommentLikeFilter/>}
                {...props}
  >
    <Datagrid>
      <TextField source="id"/>
      <DateField source="createdAt" label={'Created At'} showTime={true}/>
      <ReferenceField label="Comment" source="commentId" reference="publication_comments">
        <TextField source="message"/>
      </ReferenceField>
      <ReferenceField label="User" source="user.id" reference="users">
        <TextField source="email"/>
      </ReferenceField>
      <ShowButton/>
    </Datagrid>
  </List>);
};

const CommentLikeFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="User" source="user" reference="users" alwaysOn filterToQuery={(searchText) => {
      if (searchText) {
        return {searchQuery: searchText};
      }
    }}>
      <AutocompleteInput optionText="email"/>
    </ReferenceInput>
  </Filter>
);

export const CommentLikeShow = props => {
  useAuthenticated();

  return (
    <Show title="Comment Like" {...props}>
      <SimpleShowLayout>
        <DateField label="Created At" source="createdAt" showTime={true}/>
        <ReferenceField label="Comment" source="commentId" reference="publication_comments">
          <TextField source="message"/>
        </ReferenceField>
        <ReferenceField label="User" source="user.id" reference="users">
          <TextField source="email"/>
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};

const commentLikesConfiguration = {
  icon: ThumbUpIcon,
  list: CommentLikeList,
  options: {label: 'Comment Likes'},
  show: CommentLikeShow,
};

export default commentLikesConfiguration;