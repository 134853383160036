import React from 'react';
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  ImageField,
  ImageInput,
  List,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  useAuthenticated
} from 'react-admin';
import {DEFAULT_ITEMS_PER_PAGE} from "../constants/Request";
import {MAX_IMAGE_SIZE, transformImage} from "../constants/Image";
import {Collections} from "@material-ui/icons";

export const GroupImageList = props => {
  useAuthenticated();

  return (<List perPage={DEFAULT_ITEMS_PER_PAGE} {...props}>
    <Datagrid>
      <TextField source="id" sortable={false}/>
      <ImageField source="image.url" label={'Image'} sortable={false}/>
      <EditButton/>
      <ShowButton/>
    </Datagrid>
  </List>);
};

export const GroupImageShow = props => {
  useAuthenticated();

  return (
    <Show title="Group Image" {...props}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <ImageField source="image.url" label={'Image'}/>
      </SimpleShowLayout>
    </Show>
  );
};

export const GroupImageCreate = props => {
  useAuthenticated();

  const transform = async data => {
    data.image = await transformImage(data.image);

    return data;
  };

  return (
    <Create title="Add Group Image" transform={transform} {...props}>
      <SimpleForm>
        <ImageInput source="image" accept="image/png,image/jpg,image/jpeg" maxSize={MAX_IMAGE_SIZE}>
          <ImageField source="url"/>
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
export const GroupImageEdit = props => {
  useAuthenticated();

  const transform = async data => {

    return {
      image: await transformImage(data.image),
    };
  };

  return (
    <Edit title="Edit Group Image" transform={transform} {...props}>
      <SimpleForm>
        <ImageInput source="image" accept="image/png,image/jpg,image/jpeg" maxSize={MAX_IMAGE_SIZE}>
          <ImageField source="url"/>
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

const groupImagesConfiguration = {
  icon: Collections,
  list: GroupImageList,
  create: GroupImageCreate,
  edit: GroupImageEdit,
  options: {label: 'Groups Images'},
  show: GroupImageShow,
};

export default groupImagesConfiguration;